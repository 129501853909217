import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import playstoreImg from "../../img/google-play-store.png";
import appstoreImg from "../../img/app-store.png";
import metamaskImg from "../../img/metamask.png";

import Modal from "../__Shared/Modal";
import settings from "../../config";

import getAuthorizedApi from "../../providers/api";
import { AuthContext } from "../../providers/AuthProvider";
import GetAssistanceModal from "../__Shared/GetAssistanceModal";
import OnboardingNextPrevButton from "../__Shared/OnboardingNextPrevButton";

import { ReactComponent as DiscListStyle } from "../../img/icons/disc-liststyle.svg";
import { ReactComponent as Close } from "../../img/icons/close.svg";
import { ReactComponent as Download } from "../../img/icons/download.svg";
export default function NationalityStep() {
  const { user, loading } = useContext(AuthContext);
  const navigation = useNavigate();

  const [fetching, setLoading] = useState(loading);
  const [hearAboutUs, setHearAboutUs] = useState(null);
  const [marketingEmailConsent, setMarketingEmailConsent] = useState(null);
  const [showHearAboutUs, setShowHearAboutUs] = useState(false);
  const [showError, setShowError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [hasReadWhitepaper, setHasReadWhitepaper] = useState(false);
  const [errorWhitepaper, setErrorWhitepaper] = useState(false);

  const [showHelp, setShowHelp] = useState(false);

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null;

  useEffect(() => {
    let referralCode = localStorage.getItem("referralCode") || null;
    const checkUserLogin = async () => {
      try {
        const response = await getAuthorizedApi(user).getUserDetails();
        setLoading(false);

        localStorage.setItem(
          "marketingEmailConsent",
          response.marketingEmailConsent
        );
        localStorage.setItem("hearAboutUs", response.hearAboutUs);
        if (referralCode === null || referralCode === "null") {
          localStorage.setItem("referralCode", response.referralCode);
          referralCode = response.referralCode;
        }

        if (response.hearAboutUs && response.hearAboutUs !== "null")
          setHearAboutUs(response.hearAboutUs);
        setMarketingEmailConsent(response.marketingEmailConsent);

        if (referralCode === null || referralCode === "null") {
          setShowHearAboutUs(true);
        } else {
          setShowHearAboutUs(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    if (user) {
      checkUserLogin();
    } else {
      if (!loading) {
        if (!referralCode) setShowHearAboutUs(true);
        setLoading(false);
      }
    }
  }, [user, loading, navigation]);

  const handleNext = async () => {
    let error = false;

    // Validate hearAboutUs
    if (
      showHearAboutUs &&
      (hearAboutUs === null || hearAboutUs.trim() === "")
    ) {
      document.getElementById("hearAboutUs").focus();
      setShowError(true);
      error = true;
    } else {
      setShowError(false);
    }

    // Validate whitepaper acknowledgment
    if (!hasReadWhitepaper) {
      setErrorWhitepaper(true);
      error = true;
    } else {
      setErrorWhitepaper(false);
    }

    if (error) return;

    localStorage.setItem("hearAboutUs", hearAboutUs);

    try {
      setLoading(true);
      const response = await getAuthorizedApi(user).loginUser();
      localStorage.setItem("purchaseId", response.purchaseId);
      navigation("/onboarding/login");
    } catch (error) {
      navigation("/onboarding/login");
    } finally {
      setLoading(false);
    }
  };

  const handleMarketingEmailConsent = (event) => {
    if (event.target.checked) {
      localStorage.setItem("marketingEmailConsent", true);
      setMarketingEmailConsent(true);
    } else {
      localStorage.setItem("marketingEmailConsent", false);
      setMarketingEmailConsent(false);
    }
  };

  const handleNeedHelp = (e) => {
    e.preventDefault();
    setShowHelp(true);
  };

  return (
    <>
      <GetAssistanceModal open={showHelp} onClose={setShowHelp} />
      <div className="position-relative nationality-step-block">
        <h3 className="step1-title">Start</h3>
        <div className="row">
          <div className="col-12">
            <div className="preparation-box">
              <span className="preparation-title">
                To purchase LEOS you will need:
              </span>

              <div className="preparation-list">
                <ul>
                  <li className="d-flex flex-column gap-1">
                    <div className="form-check align-items-center square-tick-check2 mb-0 gap-2 d-flex ps-0">
                      <DiscListStyle />
                      <div className="create-walletapp">
                        Create a Pangea account on the
                        <span className="fw-bold">
                          {" "}
                          United Citizens Wallet{" "}
                        </span>
                        to successfully finish the purchase:
                      </div>
                    </div>
                    <div className="alert download-alert d-flex ps-4 flex-column gap-2 download-app-step mb-0">
                      <div className="d-flex create-walletapp flex-md-row flex-column text-black gap-2">
                        <div className="d-flex align-items-center">
                          1. Download the app
                        </div>

                        <div className="text-center">
                          {" "}
                          <Link
                            to={settings.config.googlePlayStoreUrl}
                            target="_blank"
                          >
                            <img src={playstoreImg} alt="" height={35} />
                          </Link>
                        </div>
                        <div className="text-center">
                          <Link
                            to={settings.config.appStoreUrl}
                            target="_blank"
                          >
                            <img src={appstoreImg} alt="" height={35} />
                          </Link>
                        </div>
                      </div>
                      <p className="create-walletapp">
                        2. Create an account by following the steps in the app
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-center gap-1">
                    <div className="form-check align-items-center square-tick-check2 mb-0 d-flex ps-0 gap-2">
                      <DiscListStyle />
                      <div className="create-walletapp">
                        Valid <span className="fw-bold">identity document</span>{" "}
                        from a{" "}
                        <span className="create-walletapp">
                          <button
                            className="country_modal"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            supported country
                          </button>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex align-items-center gap-1 flex-md-row flex-column">
                    <div className="form-check align-items-center square-tick-check2 mb-0 d-flex ps-0 gap-2">
                      <DiscListStyle />

                      <div className="metamask-other-wallet">
                        <span className="fw-bold">
                          Metamask or other crypto wallet
                        </span>{" "}
                        with {purchase?.amount} Ether + enough to cover
                        transaction fees
                      </div>
                    </div>
                    <div className="download-btn-bar">
                      <Link
                        className="meta-ex-btn flex-md-row d-flex text-black gap-2 align-items-center"
                        to="https://metamask.io/download/"
                        target="_blank"
                      >
                        <Download height={10} width={9} />
                        <img src={metamaskImg} alt="" width={81} height={15} />
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 mt-4">
            <div className="preparation-box pb-4">
              <span className="preparation-title ">Please let us know:</span>
              {showHearAboutUs === true && (
                <div className="mt-4 px-4">
                  <label htmlFor="hearAboutUs" className="form-label mb-0">
                    How did you hear about us?*
                  </label>
                  <input
                    defaultValue={hearAboutUs}
                    type="text"
                    className="form-control form-control-custom"
                    id="hearAboutUs"
                    placeholder="Andreas Antonopoulos, Twitter Post from @PangeaWeb4, Binance exchange listing... etc..."
                    onChange={(e) => setHearAboutUs(e.target.value)}
                  />
                  {showError && (
                    <div className="form-text text-danger">Required</div>
                  )}
                </div>
              )}

              <div className="form-check square-tick-check mb-0 mt-4 d-flex gap-2">
                <input
                  defaultValue={hearAboutUs}
                  className="form-check-input m-0"
                  type="checkbox"
                  id="subscribe_marketing"
                  onChange={handleMarketingEmailConsent}
                  checked={marketingEmailConsent === true}
                />
                <label
                  className="form-check-label"
                  htmlFor="subscribe_marketing"
                >
                  Yes I'd like to receive marketing updates about Pangea and
                  LEOS!
                  <br />
                  <span className="gray-text">
                    By checking this box, I agree to receive marketing
                    communications, updates, and special offers via email from
                    Pangea. I understand I can unsubscribe at any time and
                    acknowledge that my information will be handled in
                    accordance with Pangea's{" "}
                    <Link
                      className="gray-text text-decoration-underline"
                      to="https://pangea-web4-world.webflow.io/legal/privacy-notice"
                      target="_blank"
                    >
                      Privacy Notice.
                    </Link>
                  </span>
                </label>
              </div>

              <div className="form-check square-tick-check mb-0 mt-3 d-flex gap-2 ">
                <input
                  defaultValue={hasReadWhitepaper}
                  className="form-check-input m-0"
                  type="checkbox"
                  id="has_read_whitepaper"
                  onChange={(e) => {
                    setHasReadWhitepaper(e.target.checked);
                    localStorage.setItem("hasReadWhitepaper", e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="has_read_whitepaper"
                >
                  I have read and taken due note of the
                  <a
                    href="https://github.com/Tonomy-Foundation/documentation/blob/24181bb322b208d0bc1bdb8e9402e7f6f078f992/whitepapers/Pangea%20and%20LEOS%20Official%20Whitepaper%20-%20EU%20Regulated%20v1.2.pdf?raw=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-1"
                  >
                    Whitepaper
                  </a>
                  . I will act in accordance with it.
                </label>
              </div>
              {errorWhitepaper && (
                <div className="d-block ps-4 form-text text-danger">
                  You must read and acknowledge the whitepaper to proceed.
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col text-center">
            <button onClick={handleNeedHelp} className="prev-btn">
              Get Assistance{" "}
            </button>
            <OnboardingNextPrevButton
              disabledNext={fetching}
              hidePrev={true}
              onNext={handleNext}
            />
          </div>
        </div>

        {fetching === true && (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        )}
      </div>

      {/* country modal */}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="model-title">Countries</h3>
            <button
              className="close_btn"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <Close />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h5 className="region-title mb-3">Europe</h5>
          <div className="listing-item-box">
            <ul className="m-0 p-0 country_list">
              <li>Andorra</li>
              <li>Austria</li>
              <li>Belgium</li>
              <li>Bulgaria</li>
              <li>Croatia</li>
              <li>Cyprus</li>
              <li>Czech Republic</li>
              <li>Denmark</li>
              <li>Estonia</li>
              <li>Finland</li>
              <li>France</li>
              <li>Germany</li>
              <li>Gibraltar</li>
              <li>Greece</li>
              <li>Hungary</li>
              <li>Ireland</li>
              <li>Italy</li>
              <li>Latvia</li>
              <li>Liechtenstein</li>
              <li>Lithuania</li>
              <li>Luxembourg</li>
              <li>Netherlands</li>
              <li>Norway</li>
              <li>Poland</li>
              <li>Portugal</li>
              <li>Romania</li>
              <li>Slovakia</li>
              <li>Slovenia</li>
              <li>Spain</li>
              <li>Sweden</li>
              <li>Switzerland</li>
              <li>Taiwan</li>
              <li>Turkiye</li>
              <li>Ukraine</li>
            </ul>
          </div>
          <h5 className="region-title mb-3 mt-4">North America</h5>
          <div className="listing-item-box">
            <ul className="m-0 p-0 country_list">
              <li>Canada</li>
            </ul>
          </div>
          <h5 className="region-title mb-3 mt-4">Oceania</h5>
          <div className="listing-item-box">
            <ul className="m-0 p-0 country_list">
              <li>Australia</li>
              <li>New Zealand</li>
            </ul>
          </div>
          <h5 className="region-title mb-3 mt-4">Asia</h5>
          <div className="listing-item-box">
            <ul className="m-0 p-0 country_list">
              <li>India</li>
              <li>Indonesia</li>
              <li>Singapore</li>
              <li>Vietnam</li>
            </ul>
          </div>
          <h5 className="region-title mb-3 mt-4">Latin America & Caribbean</h5>
          <div className="listing-item-box">
            <ul className="m-0 p-0 country_list">
              <li>Argentina</li>
              <li>Brazil</li>
              <li>Colombia</li>
              <li>Costa Rica</li>
              <li>El Salvador</li>
              <li>Panama</li>
              <li>Puerto Rico</li>
            </ul>
          </div>
          <h5 className="region-title mb-3 mt-4">Middle East</h5>
          <div className="listing-item-box">
            <ul className="m-0 p-0">
              <li>United Arab Emirates</li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
